nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-menu {
  background-color: #222;
  color: #fff;
  border: none;
  padding: 1rem;
  cursor: pointer;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.menu.open {
  max-height: 3000px;
}

.menu li {
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.menu li:hover {
  background-color: #eee;
}

.menu li span.menu-item-text {
  margin-left: 1rem;
}

.dark-red {
  color: #5c1a0f;
}

.dark-green {
  color: #004d26;
}

.dark-blue {
  color: #1a294f;
}

.dark-purple {
  color: #330033;
}

.dark-brown {
  color: #4b2c1a;
}

.dark-gray {
  color: #1f1f1f;
}

.dark-teal {
  color: #005f5a;
}

.dark-orange {
  color: #664000;
}
