.App {
  text-align: center;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282c34;
  padding: 1rem;
}

.App-logo {
  height: 80px; /* Ajusta el tamaño según sea necesario */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-pair {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-red {
  color: #5c1a0f;
}

.dark-green {
  color: #004d26;
}

.dark-blue {
  color: #1a294f;
}

.dark-purple {
  color: #330033;
}

.dark-brown {
  color: #4b2c1a;
}

.dark-gray {
  color: #1f1f1f;
}

.dark-teal {
  color: #005f5a;
}

.dark-orange {
  color: #664000;
}
