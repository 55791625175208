/* PrivacyNotice.css */
.privacy-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .privacy-notice {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    text-align: center;
  }
  
  .privacy-notice p {
    margin: 0 0 10px;
  }
  
  .privacy-notice button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .privacy-notice button:hover {
    background-color: #0056b3;
  }
  
.dark-red {
  color: #5c1a0f;
}

.dark-green {
  color: #004d26;
}

.dark-blue {
  color: #1a294f;
}

.dark-purple {
  color: #330033;
}

.dark-brown {
  color: #4b2c1a;
}

.dark-gray {
  color: #1f1f1f;
}

.dark-teal {
  color: #005f5a;
}

.dark-orange {
  color: #664000;
}
