ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin: 5px 0;
  }
  
  .category-button {
    background-color: #2ab98e;
    border: none;
    color: rgb(4, 18, 80);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .category-button:hover {
    background-color: #45a049;
  }
  
  .subcategory-button {
    background-color: #67b9a5;
    border: none;
    color: rgb(75, 4, 4);
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
    transition: background-color 0.3s ease;
  }
  
  .subcategory-button:hover {
    background-color: #77b5d1;
  }
  