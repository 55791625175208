/* Archivo CSS para coincidir con el logo */

/* Colores principales */
:root {
    --primary-color: #1A1A1A; /* Azul oscuro (similar al color del borde del logo) */
    --secondary-color: #E0E7EF; /* Azul claro (similar al fondo del logo) */
    --accent-color: #FFFFFF; /* Blanco */
}

body {
    font-family: 'Arial', sans-serif;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    margin: 0;
    padding: 0;
}

.header {
    background-color: var(--primary-color);
    color: var(--accent-color);
    padding: 20px;
    text-align: center;
    border-bottom: 5px solid var(--primary-color);
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.header h1 {
    margin: 0;
    font-size: 2.5em;
}

.header p {
    margin: 5px 0 0 0;
    font-size: 1.2em;
}

.button {
    background-color: var(--primary-color);
    color: var(--accent-color);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.button:hover {
    background-color: #333333; /* Un tono más oscuro de azul para el hover */
}

.card {
    background-color: var(--accent-color);
    color: var(--primary-color);
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
}

.text-center {
    text-align: center;
}

.rounded {
    border-radius: 10px;
}

.shadow {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dark-red {
    color: #5c1a0f;
  }
  
  .dark-green {
    color: #004d26;
  }
  
  .dark-blue {
    color: #1a294f;
  }
  
  .dark-purple {
    color: #330033;
  }
  
  .dark-brown {
    color: #4b2c1a;
  }
  
  .dark-gray {
    color: #1f1f1f;
  }
  
  .dark-teal {
    color: #005f5a;
  }
  
  .dark-orange {
    color: #664000;
  }
  